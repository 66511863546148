*{
    font-size: 14px;
}

.navbar-container{
    background-color: rgb(0, 0, 0, 0.0);
    margin: auto;
    height: 52px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all 1s;
}

.text-dark{
    color: #2d2d2d!important;
}

.navbar-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inner-left-nav-item{
    display: flex;
    gap: 25px;
    align-items: center;
}

.inner-left-nav-item a{
    color: rgb(226, 226, 226);
    text-decoration: none;
    font-weight: 500;
}

.inner-left-nav-item a:hover{
    color: #fff;
}

.bold{
    font-weight: bold!important;
}

.inner-right-nav-item{
    width: 30%;
    position: relative;
}

.search-inpt{
    background-color: transparent;
    color: #fff;
    padding-left: 24px;
}

.search-inpt::placeholder{
    color: #f0f0f0!important;
}

.inpt-cont-search{
    position: relative;
}

.input-svg-color{
    background-color: transparent;
    fill: #fff;
    position: absolute;
    top: 7px;
    left: 4px;
}

.footer-inner-left p{
    color: #fff;
    font-size: 11px;
}

.footer-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.links-footer{
    display: flex;
    align-items: center;
    gap: 20px;
}

.links-footer a{
    color: #fff;
    font-size: 11px;
    font-weight: bold;
}

.footer-inner-right *{
    font-size: 11px;
    color: #fff;
    font-weight: bold;
}

.home-slider-items{
    height: 600px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1680px;
    width: 100%;
    display: flex;
    align-items: center;
}

.rgbgrad-cont-image{
    height: 100%;
    width: 100%;
    position: relative;
    background: linear-gradient(rgb(0, 0, 0), #3123ae5f, #000);
    border-radius: 0px 0px 20px 20px;
}

.gradient-slide{
    background: linear-gradient(to top right, #f09, #3123ae5f, #0ff);
    position: absolute;
}

.rgbgrad-cont-image img{
    opacity: 0.6;
    border-radius: 0px 0px 20px 20px;
}

.header-sflix{
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.23;
    color: #fff;
}

.swiper {
    width: 100%;
    height: 100%;
}
  
.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-courses-container{
    position: relative;
    height: auto;
    display: flex;
    align-items: center;
}

.slide-courses-container .swiper{
    height: auto!important;
    /*
    overflow-x: unset!important;
    */
}

.slide-courses-container .swiper .swiper-wrapper{
    height: auto!important;
}

.slide-courses-container .swiper-button-prev.swiper-button-disabled {
    display: none!important;
}

.slide-courses-container .swiper-button-next.swiper-button-disabled{
    display: none!important;
}

.slide-courses-container .swiper-button-next, .slide-courses-container .swiper-button-prev {
    background-color: #fff;
    box-shadow: 0 0 6px rgb(0 0 0 / 10%);
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.slide-courses-container .swiper-button-next:hover, .slide-courses-container .swiper-button-prev:hover {
    box-shadow: 0 0 6px rgb(0 0 0 / 40%);
    transition: all 3s;
}

.slide-courses-container .swiper-button-next{
    right: 0;
}

.slide-courses-container .swiper-button-prev {
    left: 0;
}

.slide-courses-container .swiper-button-next:after, .slide-courses-container .swiper-button-prev:after {
    font-size: 16px!important;
}

.slide-courses-container .swiper-slide{
    width: 200px!important;
    height: auto;
}

.items-courses-slide{
    height: 100%!important;
}

.items-courses-slide .swiper-slide a{
    display: block;
    width: 100%;
    border-radius: 12px;
}

.card-item-ssflix{
    width: 100%;
    height: 240px;
    position: relative;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 7px rgb(0 0 0 / 25%)
}

.card-item-ssflix .card-body{
    padding: 0px;
    height: 100%;
    width: 100%;
}

.card-item-ssflix .card-body img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.svg-c-white{
    background-color: transparent;
    fill: #fff;
}

.course-sl-item:hover .more-info-course{
    opacity: 1;
}

.more-info-course{
    opacity: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    background: #11603e;
    border-radius: 12px;
    transition: all 0.7s;
}

.course-image-more-cont{
    height: 130px;
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;
}

.container-items-course{
    text-align: start;
    width: 100%;
    padding: 7px;
}

.h1-course-inner-ttl{
    font-size: 14px;
    font-weight: bold;
}

.textupper{
    text-transform: uppercase;
}

.textTruncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fixed-bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.more-details-play-course{
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

.divider{
    border-top: 2px solid rgba(255, 255, 255, 0.1);
}

.inner-bg-dv-dts{
    width: 100%;
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 12px 12px;
    background-color: rgb(0, 0, 0,0.5);
}


.item-courses-search{
    min-height: 150px;
    display: flex;
    align-items: flex-start;
    position: relative;
}

.item-container-image{
    width: 380px;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 7px rgb(0 0 0 / 25%)
}

.item-container-image img{
    width: 100%;
    height: 100%;
    max-height: 150px;
    object-fit: cover;
}

.heading_h3_md{
    font-size: 1.6rem;
    color: #fff;
    font-weight: 700;
}

.fl-rt-cont{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hid-mb-size{
    display: none;
}

.account-dropdown-mzd{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 300px;
    right: 0;
    top: 40px;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.5s;
    z-index: 1000!important;
}

.profile:hover .account-dropdown-mzd{
    opacity: 1;
    visibility: visible;
    z-index: 1000!important;
}

.account-dropdown-mzd a:hover{
    color: #196242;
}

.border-bottom{
    border-bottom: 1px solid #dddddd;
}

.options-section-nav-account a{
    display: block;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.heading_h1{
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 1.25;
}

.ant-tabs-nav::before {
    border-bottom: 1px solid #fff!important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #11603e!important;
}

.ant-tabs-tab:hover{
    color: #11603e!important;
}

.ant-tabs .ant-tabs-ink-bar{
    background: #11603e!important;
}

.form-row-sflix{
    display: flex;
    flex-flow: row!important;
    gap: 18px;
}

.bg-succuss-sflix{
    background-color: #11603e!important;
}

.profile-image-container{
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.size-profile-cont{
    width: 50%;
}

.user-profile-image-container{
    background-color: #fff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #eeeeee;
    overflow: hidden;
    cursor: pointer;
}

.user-profile-image-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.description-course-slide-last-ones{
    color: #fff;
    font-size: 1.2vw;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.1vw;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 45%);
    text-align: start;
}

.description-course-container{
    position: absolute;
    width: 500px;
    z-index: 100;
    bottom: 190px;
    text-align: start;
}

.button-more-info-style{
    background-color: rgba(109, 109, 110, 0.7);
    color: #fff;
    border: 1px solid rgba(109, 109, 110, 0.7);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.button-more-info-style:hover{
    color: #fff!important;
}

.svg-white{
    background-color: transparent;
    fill: #fff;
}

.svg-green{
    background-color: transparent;
    fill: #0fa966;
}

.bg-hidding-last{
    background-color: #2d2d2d;;
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: -70px;
    left: 0;
    z-index: 5;
    filter: blur(60px);
    padding: 0px;
}

.btn-more-info-course{
    background-color: #2d2d2dca;
}

.btn-more-info-course:hover{
    background-color: #2d2d2d!important;
}

.course-video-container-img{
    height: 340px;
    border-radius: 8px 8px 0px 0px;
    position: relative;
    overflow: hidden;
}

.bg-blur-course-mr-info{
    background-color: #2d2d2d;;
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: -90px;
    left: -10px;
    z-index: 5;
    filter: blur(40px);
    padding: 0px;
}

.course-vd-info-view-more{
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 10!important;
}

.headign_h1{
    font-size: 1.6rem;
    color: #fff;
}

.course-video-container-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-info-modal-course-mr{
    padding: 20px;
}

.text-rell-info{
    color: #11603e;
}

.info-beg-course{
    display: flex;
    gap: 15px;
    align-items: center;
}

.heading-title{
    font-size: 1.1rem;
    color: #fff;
}

.module-item-inner{
    display: flex;
    align-items: center;
}

.img-module-item{
    width: 250px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
}

.img-module-item img{
    width: 100%;
    height: 100%;
}

.module-info-info{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-left: 15px;
}

.related-courses{
}

.related-courses .swiper .swiper-slide{
    width: 140px!important;
}

.related-courses .swiper .swiper-slide .card-item-ssflix {
    height: 180px!important;
}

.related-courses{
    padding: 20px;
}

.container-list-courses-tpydf{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.course-item-df-lst{
    width: 200px!important;
    height: 30%!important;
    position: relative;
}

.course-image-more-cont img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.course-item-df-lst .more-info-course{
    opacity: 0;
    z-index: 1000;
    width: 220px;
    height: 360px;
    position: absolute;
    top: -45px;
    left: -10px;
    background: #11603e;
    border-radius: 12px;
    transition: all 0.7s;
    box-shadow: 0px 4px 10px #000;
}

.course-item-df-lst .more-info-course .course-image-more-cont{
    height: 180px;
}

.bg-pagination-cont{
    background-color: #0fa966;
}

.insctructors-list{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 12px;
}

.insctructor-container-prof{
    border: 1px solid #5c5c5c!important;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 7px;
}

.img-insctructor-cont{
    width: 90px;
    height: 90px;
    border-radius: 100px;
    background-color: #dddddd;
    overflow: hidden;
}

.img-insctructor-cont img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.login-section{
    height: 100%;
}

.login-wrapper{
    margin: 0;
    padding: 0;
    z-index: 10;
}

.bg-wrapper{
    background-size: cover;
    display: block;
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: -1;
    background-color: #000;
}

.bg-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
}

.login-body{
    background-color: transparent;
    margin: 0 auto;
    max-width: 450px;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-content{
    width: 100%;
    min-height: 500px;
    background-color: rgba(0,0,0,.75);
    color: #fff;
    border-radius: 10px;
    padding: 50px;
}

.navbar-wi-logo{
    position: absolute;
}

.navbar-wi-logo img{
    width: 130px;
    height: 130px;
    object-fit: contain;
}

.bg-main-c{
    background-color: #2d2d2d!important;
}

.header-login{
    font-size: 28px;
    font-weight: bold;
}

.login-content input{
    background: #333;
    border: 1px solid #333;
    color: #fff!important;
}

.login-content input:hover, .login-content input:active{
    color: #333 !important;
}

.login-content .ant-input-affix-wrapper{
    background-color: #333!important;
    border: 1px solid #333;
}

.login-content .ant-input-affix-wrapper svg{
    fill: #fff!important;
}

.nav-b-planstype{
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.packages-cont{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 12px;
}

.card-package-cont{
    background: #fff;
    padding: 20px;
    border-radius: 16px;
}

.paymnet-cont{
    position: relative;
    background-color: transparent;
    margin: 0 auto;
    max-width: 650px;
    min-height: 500px;
    border-radius: 8px;
    background-color: #fff;
}

.border-red{
    border: 1px solid red;
}

.wrapper-container{
    width: 100%;
    height: 100vh;
    display: flex;
}

.left-nav{
    min-width: 270px;
    height: 100%;
    background-color: #fff;
}

.navbar-container-left{
    padding-left: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.layout-wrapper-szn{
    flex-grow: 1;
    overflow-y: scroll;
}

.logo-admin{
    width: 160px;
    height: 80px;
}

.logo-admin img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nav-options-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.nav-options-list li a{
    display: flex;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #5c5c5c;
    align-items: center;
    gap: 10px;
    border-radius: 5px 0px 0px 5px;
    transition: all 0.5s;
}

.nav-options-list li a:hover{
    background-color: #eeeeee;
}

.nav-options-list li a .icon-nav-opt{
    padding: 3px;
}

.nav-options-list li a .icon-nav-opt svg{
    width: 23px;
    height: 23px;
    fill: #5c5c5c;
}

.options-nav svg{
    fill: #144831;
    width: 25px;
    height: 25px;
}

.drop-arrow{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.nav-options-list li a .drop-arrow svg{
    fill: #5c5c5c;
    transition: all 0.5s;
}

[aria-expanded="true"] .drop-arrow svg{
    transform: rotate(90deg);
    transition: all 0.5;
}

.nav-options-list li a.active{
    background-color: #11603e;
    color: #fff;
}

.nav-options-list li a.active .icon-nav-opt{
    box-shadow: 0px 0px 5px #c1c1c1;
    border-radius: 7px;
}

.nav-options-list li a.active .drop-arrow svg,.nav-options-list li a.active .icon-nav-opt svg{
    fill: #fff;
}

.collapse-items-more{
    padding-left: 20px;
}

.collapse-items-more ul li a{
    font-size: 12px!important;
}

.nav-inner{
    background-color: #fff;
}

.nav-inner nav{
    padding: 15px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.options-nav{
    display: flex;
    align-items: center;
    gap: 15px;
}

.options-nav .profile-desc{
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.ttl-dash{
    display: flex;
    align-items: center;
    gap: 10px;
}

.ttl-dash a{
    font-size: 18px;
    font-weight: 700;
}

.more-nav-items svg{
    width: 23px;
    height: 23px;
    fill: #11603e;
}

.main-cont-inner{
    padding-top: 20px;
    padding: 20px;
}

.plaform-cards{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 15px;
}

.hight-ttl-card{
    font-size: 34px;
    font-weight: 600;
}

.plaform-cards .card-flix-tp{
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    padding-bottom: 5px;
}

.card-flix-tp .card-inner-cont-fl{
    display: flex;
    justify-content: space-between;
}

.icon-ilustration{
    width: 200px!important;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.icon-ilustration{
    width: 50px;
    height: 50px;
}

.graph-cont-items{
}

.graph-card-item{
    background-color: #fff;
    padding: 15px;
}

.flex-ttp-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.video-background{
    background-color: #f6f6f6;
    border-radius: 5px;
    width: 100%;
    height: 200px;
    cursor: pointer;
    overflow: hidden;
}

.video-background img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modulo-capa{
    background-color: #f6f6f6;
    width: 70px;
    height: 70px;
    border-radius: 3px;
}

.modulo-capa img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.flex-graphs-container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 300px;
}

.loader-flix-cont{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 10%);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-form input[type="file"] {
    display: none!important
}

@media only screen and (max-width: 500px) {

}

@media only screen and (max-width: 687px) {
    .controls .controlls-cont button svg {
        fill: #fff;
        background-color: transparent;
        width: 23px!important;
        height: 23px!important;
    }

    .mobile-visible{
        font-size: 12px;
    }
    
    .controls .controlls-cont{
        height: 50px;
    }

    .head-ttle-video-more{
        font-size: 14px!important;
    }

    .course-modules-vds-container{
        bottom: 90px;
    }

    .inner-modules-container .swiper .swiper-slide{
        width: 140px!important;
        height: 90px;
    }

    .controls .settings .vd-quality .vd-quality-option{
        font-size: 12px;
    }

    .hide-mobile-small{
        display: none!important;
    }

    .mobile-visible-small{
        display: block;
    }
}

@media only screen and (max-width: 887px) {
    .controls .controlls-cont button svg {
        width: 25px;
        height: 25px;
    }

    .mobile-visible{
        display: block!important;
    }

    .course-description-video-strt{
        display: none;
    }

    .back-optin button svg {
        width: 30px;
        height: 30px;
    }

    .head-ttle-video-more{
        font-size: 18px;
    }
}

@media only screen and (max-width: 1067px) {
    .controls .controlls-cont button svg {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (max-width: 1680px) {
    .rgbgrad-cont-image {
        border-radius: 0px;
    }
    .rgbgrad-cont-image img {
        border-radius: 0px;
    }
}

@media only screen and (min-width: 688px) {
    .mobile-controlls{
        opacity: 0;
        visibility: hidden;
    }
}

@media only screen and (min-width: 1067px) {
    .course-item-df-lst{
        width: calc(20% - 0.8rem);
    }

    /*
    .course-item-df-lst:not(:nth-child(5n)) {
        margin-right: 1rem!important;
    }

    .course-item-df-lst:not(:nth-child(4n)) {
        margin-right: 0;
    }
    */
}